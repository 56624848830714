import React from 'react';

export default function Footer(){
    return (
        <footer className="footer d-flex h-5 fade-in-slide-up-delayed">
            <div className="footer-text row justify-content-center align-self-center">
                Trackella is not an official business or company nor do we claim to be one. We are an idea for the
                future.
            </div>
        </footer>
    );
}