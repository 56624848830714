import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/navbar.css';
import App from './App';
import Footer from "./Footer";
import AboutUs from "./AboutUs.js";
import { BrowserRouter, Route} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

