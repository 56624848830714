import React from 'react';
import { useHistory } from "react-router-dom";

export default function Navbar() {
    return (
        <header>
            <div className="container-fluid px-0">
                <nav className="navbar navbar-expand-sm navbar-light bg-white py-0 px-0">
                    <a className="navbar-brand" href="#"><img id="logo" src="images/trackella-standard.png"></img>&nbsp;&nbsp;&nbsp;Trackella</a>
                    <span className="v-line"></span>
                    <button className="navbar-toggler mr-3" type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav d-flex">
                            <li className="nav-item active">
                                <a className="nav-link" href="/home">Home</a>
                            </li>

                            <li className="nav-item active">
                                <a className="nav-link" href="/about-us">About Us</a>
                            </li>

                            <div className="ml-auto">
                                <li className="nav-item active nav-item-right">
                                    <a className="nav-link nav-link-login" href="/login">Login</a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}