import React from 'react';

export default function Home(){
    return (
        <div className="container h-100 fade-in-slide-up">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="container" style={{marginTop: '5vw'}}>
                    <img src="images/logo1.png" className="rounded mx-auto d-block trackella-round trackella-info-img"
                         alt="Trackella: Manage your time with ease."></img>
                </div>
                <p className="font-weight-bold text-center">Trackella is currently in development. Please check back later to
                    see the status.</p>
            </div>
        </div>
    );
}

