import React from 'react';

export default function AboutUs(){
    return (
        <div className="container fade-in-slide-up">
            <div className="d-flex justify-content-center align-items-center" style={{height: '90vh'}}>
                <p className="font-weight-bold text-center fade-in-slide-up">Trackella is an application in development intended to make your life easier by allowing you to track your daily activities.
                    <br/>
                    <br/>
                    Designed as a portfolio project, built as a solution.
                </p>
            </div>
        </div>
    );
}