import React from 'react';
import {Route, BrowserRouter, Routes} from "react-router-dom";

import Home from './Home.js';
import Footer from "./Footer.js";
import Navbar from "./Navbar.js";
import AboutUs from "./AboutUs";
import Login from "./Login.js";



function App() {
  return (
      <div>
        <Navbar/>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/home" element={<Home/>} />
                <Route exact path="/index.html" element={<Home/>} />
                <Route exact path="/about-us" element={<AboutUs/>} />
                <Route exact path="/login" element={<Login/>} />
            </Routes>
        </BrowserRouter>
          <Footer />
      </div>
  );
}

export default App;
