import React from 'react';

export default function Login() {
    return (
        <div className="container fade-in-slide-up">
            <div className="d-flex justify-content-center align-items-center" style={{height: '90vh'}}>
                <p className="font-weight-bold text-center" style={{fontSize: 'xx-large'}}>Account creation is opening soon!</p>
            </div>
        </div>
    );
}
